html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: inherit;
  outline: none;
  border: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

body {
  overflow-x: hidden;
  color: #fff;
  background-color: #e5e5e5;
}

img {
  width: 296px;
  height: 153px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: inherit;
}

.App {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  color: #000;
}

.App .App-container {
  color: #fff;
  width: 1366px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  background-color: #292929;
}

.App .App-container .header {
  width: 100%;
  height: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30px;
  background-color: #101010;
}

.App .App-container .header .user-info {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.App .App-container .header .user-info .logo {
  font-size: 36px;
}

.App .App-container .header .user-info .user-name {
  font-size: 20px;
}

.App .App-container .header .user-info img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.App .App-container .rides-container {
  width: 100%;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.App .App-container .rides-container .ride {
  width: 100%;
  background-color: #171717;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  position: relative;
  padding: 20px;
  border-radius: 8px;
}

.App .App-container .rides-container .ride .absolute {
  position: absolute;
  top: 25px;
  right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}

.App .App-container .rides-container .ride .absolute p {
  background-color: #000;
  padding: 2.5px 10px;
  color: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  cursor: context-menu;
  font-size: 14px;
}

.App .App-container .rides-container .ride .info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}

.App .App-container .filters-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  padding: 10px 30px;
}

.App .App-container .filters-container button {
  background-color: transparent;
  color: #ddd;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
}

.App .App-container .filters-container button:hover {
  color: #fff;
  text-decoration: underline;
}

.App .App-container .filters-container .filters-icon {
  margin-left: auto;
  cursor: context-menu;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App .App-container .filters-container .selected {
  color: #fff;
  text-decoration: underline;
}

.App .options-container {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 100px;
  width: 228px;
  height: 190px;
  background-color: #131313;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;
  gap: 20px;
}

.App .options-container .refresh-btn {
  position: absolute;
  color: #fff;
  background-color: transparent;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.App .options-container p {
  color: #a5a5a5;
  font-size: 20px;
  width: 168.45px;
  height: 37.5px;
  text-indent: 5px;
  border-bottom: 1px solid #a5a5a5;
}

.App .options-container select {
  width: 168.45px;
  height: 37.5px;
  font-size: 17px;
  background: #232323;
  color: #fff;
  padding: 5px;
  border-radius: inherit;
}

@media (max-width: 1111px) {
  .App {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 20px 0;
  }
  .App .App-container {
    width: 100%;
  }
  .App .options-container {
    margin-top: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
}

@media (max-width: 839px) {
  p {
    font-size: 14px;
  }
  .App .App-container img {
    width: 200px;
  }
  .App .App-container .rides-container .ride {
    padding: 10px;
  }
  .App .App-container .rides-container .ride .absolute {
    gap: 15px;
    top: 20px;
  }
  .App .App-container .rides-container .ride .absolute p {
    font-size: 10px;
  }
}

@media (max-width: 589px) {
  .App .App-container .header {
    padding: 0 10px;
  }
  .App .App-container .rides-container .ride {
    padding-top: 30px;
  }
  .App .App-container .rides-container .ride .absolute {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 15px;
    top: 5px;
    right: 0;
  }
  .App .App-container .rides-container .ride .absolute p {
    font-size: 10px;
  }
}

@media (max-width: 514px) {
  .App .App-container .header {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 10px;
  }
  .App .App-container .header .user-info {
    gap: 10px;
  }
  .App .App-container .header .user-info .user-name {
    font-size: 14px;
  }
  .App .App-container .header .user-info img {
    width: 35px;
    height: 35px;
  }
  .App .App-container .header .user-info p {
    font-size: 10px;
  }
  .App .App-container .header h1 {
    font-size: 25px;
  }
  .App .App-container .filters-container {
    padding: 10px 20px;
  }
  .App .App-container .filters-container button {
    font-size: 14px;
  }
  .App .options-container select {
    font-size: 14px;
  }
}

@media (max-width: 471px) {
  .App .App-container .rides-container .ride {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .App .App-container .rides-container .ride img {
    width: 95%;
  }
  .App .App-container .rides-container .ride .info {
    width: 100%;
  }
  .App .App-container .filters-container {
    gap: 10px;
    padding: 10px;
  }
  .App .App-container .filters-container button {
    font-size: 12px;
  }
}
