@import "helpers/default";
$main-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
body {
  overflow-x: hidden;
  color: #fff;
  background-color: #e5e5e5;
}
img {
  width: 296px;
  height: 153px;
  object-fit: cover;
  border-radius: inherit;
}
.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #000;

  .App-container {
    color: #fff;
    width: 1366px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: #292929;
    .header {
      width: 100%;
      height: 84px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      background-color: #101010;
      .user-info {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .logo {
          font-size: 36px;
        }
        .user-name {
          font-size: 20px;
        }
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          box-shadow: $main-box-shadow;
        }
      }
    }
    .rides-container {
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .ride {
        width: 100%;
        background-color: #171717;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        position: relative;
        padding: 20px;
        border-radius: 8px;
        .absolute {
          position: absolute;
          top: 25px;
          right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          p {
            background-color: #000;
            padding: 2.5px 10px;
            color: #fff;
            border-radius: 20px;
            box-shadow: $main-box-shadow;
            cursor: context-menu;
            font-size: 14px;
          }
        }
        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
        }
      }
    }
    .filters-container {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      padding: 10px 30px;
      button {
        background-color: transparent;
        color: #ddd;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
      .filters-icon {
        margin-left: auto;
        cursor: context-menu;
        user-select: none;
      }
      .selected {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .options-container {
    flex-shrink: 0;
    position: relative;
    align-self: flex-start;
    margin-top: 100px;
    width: 228px;
    height: 190px;
    background-color: #131313;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    gap: 20px;
    .refresh-btn {
      position: absolute;
      color: #fff;
      background-color: transparent;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    p {
      color: #a5a5a5;
      font-size: 20px;
      width: 168.45px;
      height: 37.5px;
      text-indent: 5px;
      border-bottom: 1px solid #a5a5a5;
    }
    select {
      width: 168.45px;
      height: 37.5px;
      font-size: 17px;
      background: #232323;
      color: #fff;
      padding: 5px;
      border-radius: inherit;
    }
  }
}
@media (max-width: 1111px) {
  .App {
    flex-direction: column-reverse;
    padding: 20px 0;
    .App-container {
      width: 100%;
    }

    .options-container {
      margin-top: 0;
      align-self: center;
    }
  }
}
@media (max-width: 839px) {
  p {
    font-size: 14px;
  }
  .App {
    .App-container {
      img {
        width: 200px;
      }
      .rides-container {
        .ride {
          padding: 10px;
          .absolute {
            gap: 15px;
            top: 20px;
            p {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 589px) {
  .App {
    .App-container {
      .header {
        padding: 0 10px;
      }
      .rides-container {
        .ride {
          padding-top: 30px;
          .absolute {
            width: 100%;
            justify-content: center;
            gap: 15px;
            top: 5px;
            right: 0;
            p {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 514px) {
  .App {
    .App-container {
      .header {
        justify-content: center;
        gap: 10px;
        .user-info {
          gap: 10px;
          .user-name {
            font-size: 14px;
          }
          img {
            width: 35px;
            height: 35px;
          }
          p {
            font-size: 10px;
          }
        }
        h1 {
          font-size: 25px;
        }
      }
      .filters-container {
        padding: 10px 20px;
        button {
          font-size: 14px;
        }
      }
    }
    .options-container {
      select {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 471px) {
  .App {
    .App-container {
      .rides-container {
        .ride {
          img {
            width: 95%;
          }
          flex-direction: column;
          .info {
            width: 100%;
          }
        }
      }
      .filters-container {
        gap: 10px;
        padding: 10px;
        button {
          font-size: 12px;
        }
      }
    }
  }
}
