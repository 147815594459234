html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
  border: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}
